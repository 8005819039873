import { Box, Typography } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import TABLE_OF_CONTENTS from "../../constants";
import "./home.scss";

const Home = () => {
    return <div id="app-home" className="page-container">
        <div className="banner-box-container">
            <Box
                className="banner-box"
                component="img"
                alt="A path in a forest"
                src={process.env.PUBLIC_URL + "/images/banner.jpg"}
            />
        </div>
        <section className="fullwide-section">
            <Typography variant="h3" style={{ fontWeight: 400 }}>
                It's nice to meet you.
            </Typography>
            <br />
            <Typography variant="subtitle1">
                I am Nancy Bellflower, a dental hygiene student at PIMA Medical Institute. I am originally from Los Angeles and moved to Seattle about 3 years ago. I have been passionate about health care and helping people since I was a child.  I started my career in health during high school taking an internship at a hospital helping with deskwork. Since then, I was sure I wanted to be involved in the health field in some way. After moving to Seattle, I was given the opportunity to work as a dental assistant, and I am sure this is the field I want to be in. I love volunteering with the new skill I have learned and help bring services to underserved communities. I speak Spanish and try to volunteer at events where I can help with translation, and help my community feel more at ease when dealing with dental care. 
            </Typography>
        </section>
        <div id="self-portrait" className="break-box-container">
            <Box
                className="banner-box"
                component="img"
                alt="Teeth cleaning"
                src={process.env.PUBLIC_URL + "/images/self-portrait.jpg"}
            />
        </div>
        <section id="table-of-contents" className="fullwide-section">
            <Typography variant="h3" style={{ fontWeight: 400 }}>
                Portfolio
            </Typography>
            <br />
            <ul>
                {TABLE_OF_CONTENTS.map((el, idx) =>
                    <li key={idx}>
                        <Link to={el.link}>
                            <Typography variant="h5">
                                {el.title}
                            </Typography>
                        </Link>
                        {el.links.length > 0 && <ul>
                            {
                                el.links.map((sel, jdx) =>
                                    <li key={jdx}>
                                        <Link to={sel.link}>
                                            <Typography variant="subtitle1">
                                                - {sel.title}
                                            </Typography>
                                        </Link>
                                    </li>
                                )
                            }
                        </ul>}
                    </li>
                )}
            </ul>
        </section>
        <section id="conclusion" className="fullwide-section">
            <Typography variant="h3" style={{ fontWeight: 400 }}>
                Conclusion
            </Typography>
            <br />
            <Typography variant="subtitle1">
                If you would like to schedule an appointment, feel free to contact me at (206) 657—6742 or nancybellflower@gmail.com. I want to get to know you and make sure we can make your appointments and experience as comfortable as I can.
            </Typography>
        </section>
    </div>
}

export default Home;