import { Box, Typography } from "@mui/material";
import * as React from "react";
import "./mission.scss";

const Mission = () => {
    return <div id="app-mission" className="page-container">
        <div className="banner-box-container">
            <Box
                className="banner-box"
                component="img"
                alt="Dental work."
                src={process.env.PUBLIC_URL + "/images/dental-work.jpeg"}
                style={{
                    objectPosition: "center",
                }}
            />
        </div>
        <section id="statement" className="fullwide-section">
            <Typography variant="h3" style={{ fontWeight: 400 }}>
                Mission Statement
            </Typography>
            <br />
            <Typography variant="subtitle1">
                I believe regular dental care is just as important and essential for overall health as regular physical health checks. Prevention is imperative for dental health and it should be accessible and comfortable regardless of gender, race, socioeconomic status, religion, or sexuality.
            </Typography>
            <br />
            <Typography variant="subtitle1">
                I aim to bring dental services to underserved communities and making services more accessible. As well as to help people experiencing language barriers find treatment easier, to give them information that is not easily available and to ensure they feel safe and comfortable during their dental experience.
            </Typography>
        </section>
        <div className="break-box-container">
            <Box
                className="banner-box"
                component="img"
                alt="Dental work"
                src={process.env.PUBLIC_URL + "/images/xray.jpeg"}
                style={{
                    width: "100%",
                }}
            />
        </div>
        <section id="philosophy" className="fullwide-section">
            <Typography variant="h3" style={{ fontWeight: 400 }}>
                Oral Health Philosophy Statement
            </Typography>
            <br />
            <Typography variant="subtitle1">
                Everyone deserves easy and affordable access to dental care. Prevention and education is key to ensure a healthy and lasting dentition.
            </Typography>
        </section>
        <section id="goals" className="fullwide-section">
            <Typography variant="h3" style={{ fontWeight: 400 }}>
                Professional Goals
            </Typography>
            <Typography variant="subtitle1">
                <ul>
                    <li>Make dental care accessible to everyone</li>
                    <li>Provide education</li>
                    <li>Provide preventative treatment</li>
                    <li>Ensure patients have an anxiety free experience</li>
                    <li>Understand patient-specific needs and provide services specifically tailored for each patient</li>
                </ul>
            </Typography>
        </section>
    </div>
}

export default Mission;