import { goals } from "./components/pages/goals/Goals";

const TABLE_OF_CONTENTS = [
    {
        link: "/mission",
        title: "Mission",
        buttonName: "Mission",
        links: [
            {
                link: "/mission#statement",
                title: "Mission and Vision Statement"
            },
            {
                link: "/mission#philosophy",
                title: "Oral Health Philosophy Statement"
            },
            {
                link: "/mission#goals",
                title: "Professional Goals"
            },
        ]
    },
    {
        link: "/goals",
        title: "Program Outcome Goals",
        buttonName: "Goals",
        links: goals
    },
    {
        link: "/accolades",
        title: "Recommendations, Awards, and Honors",
        buttonName: "Accolades",
        links: [
            {
                link: "/accolades#attendance",
                title: "Attendance Awards, Honor Roll, etc"
            },
            {
                link: "/goals#evaluations",
                title: "Letters of Recommendation"
            },
            {
                link: "/goals#notes",
                title: "Thank You Notes"
            },
        ]
    },
    {
        link: "/resume",
        title: "Resume",
        buttonName: "Resume",
        links: [],
    },
];

export default TABLE_OF_CONTENTS;