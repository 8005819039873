import React from "react";

const ClinicNotes = () => {
    return (
        <div style={{
            width: "100%",
            padding: "20px 10px",
            backgroundColor: "white",
            boxSizing: "border-box",
            borderRadius: "8px",
        }}>
            {`HH initial:`}
            <br />
            <br />
            {`1. Pt states she is in good general health`}
            <br />
            <br />
            {`2. Pt states she was diagnosed of iron deficiency this year, irritable bowel disease (IBD), as well as being treated for tachycardia. `}
            <br />
            {`4. Pt is currently being treated for iron deficiency, IBD, anxiety, fibromyalgia, intestinal cysts`}
            <br />
            <br />
            {`5. Pt experiences chronic pain due to fibromyalgia`}
            <br />
            <br />
            {`15. Pt has IBD causing diarrhea`}
            <br />
            <br />
            {`18. pt experiences dizziness due to iron deficiency`}
            <br />
            <br />
            {`19. pt experiences ringing in ears all the time unknown cause`}
            <br />
            <br />
            {`20. Pt experiences migraines every other week, gets visual auras before migraines is currently taking sumatriptan and it works well. `}
            <br />
            <br />
            {`21. Pt experiences fainting spells triggered by pain, pt gets nausea, and flushed before episode. Has never had fainting spell in the dental office. `}
            <br />
            <br />
            {`22. pt experiences blurred vision due to medication side effects, every few days, physician says it is normal. `}
            <br />
            <br />
            {`28. Pt experiences joint pain due to fibromyalgia`}
            <br />
            <br />
            {`35. Pt has asthma diagnosed in 2019, hardly use inhaler. Has never had an asthma attack in the dental office. `}
            <br />
            <br />
            {`37. Pt has stomach problems IBD causes severe stomach cramps, and bloating, inconsistent bowl movements. `}
            <br />
            <br />
            {`38. Pt has allergies to gabapentin causes tachycardia and rash. Pregabalin causes hives on chest and arms. Penicillin caused mother severe rash in pregnancy. Nortriptyline sensitivity causes tachycardia.`}
            <br />
            <br />
            {`42. Pt was told in 2016 she has arthritis in tailbone area. `}
            <br />
            <br />
            {`45. Pt has iron deficiency anemia`}
            <br />
            <br />
            {`47. Pt has hepes simplex`}
            <br />
            <br />
            {`58. Pt has had surgeries 3 abdominal surgeries in the last five years first one in 2018, exploratory laparoscopy, 2019 pt had appendix removed, 2020 had gallbladder removed. `}
            <br />
            <br />
            {`60. Pt wears contact lenses sometimes`}
            <br />
            <br />
            {`62. Pt is taking the following medications:`}
            <br />
            <br />
            {`albuterol 90 Microgram PRN for asthma`}
            <br />
            <br />
            {`busPIRone 5 mg twice Daily for anxiety`}
            <br />
            <br />
            {`cetirizine 5 mg once a day for allergies`}
            <br />
            <br />
            {`DULoxetine 60 mg once daily for fibromyalgia`}
            <br />
            <br />
            {`hydrOXYzine 25 mg PRN for anxiety `}
            <br />
            <br />
            {`hyoscyamine 0.125 mg PRN for spasm related to IBD`}
            <br />
            <br />
            {`norethindrone (ERRIN) 0.35 mg once daily for Birth Control Pill`}
            <br />
            <br />
            {`omeprazole 40 mg once daily for IBD`}
            <br />
            <br />
            {`SUMAtriptan 25 mg PRN for migraines`}
            <br />
            <br />
            {`oxybutynin 5 mg once daily for interstitialcystitis`}
            <br />
            <br />
            {`Iron infusions once a month for iron deficiency. `}
            <br />
            <br />
            {`64. Pt drinks alcohol about 1 a week sometimes. `}
            <br />
            <br />
            {`66. Pt is taking norethindrone birth control to prevent pregnancy`}
            <br />
            <br />
            {`Vitals: `}
            <br />
            <br />
            {`BP: 120/78`}
            <br />
            <br />
            {`P: 102`}
            <br />
            <br />
            {`Resp: 18`}
            <br />
            <br />
            {`Nancy Bellflower, DHS`}
        </div>
    )
}

export default ClinicNotes;