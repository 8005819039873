import { Box, Typography } from "@mui/material";
import * as React from "react";
import "./accolades.scss";


const Accolades = () => {
    return <div id="app-accolades" className="page-container">
        <div className="banner-box-container">
            <Box
                className="banner-box"
                component="img"
                alt="Dental work."
                src={process.env.PUBLIC_URL + "/images/toothbrushes2.jpeg"}
                style={{
                    objectPosition: "top",
                }}
            />
        </div>
        <section className="fullwide-section">
            <Typography variant="h3" style={{ fontWeight: 400 }}>
                Accolades
            </Typography>
        </section>
        <div className="break-box-container">
            <Box
                className="banner-box"
                component="img"
                alt="Dental work"
                src={process.env.PUBLIC_URL + "/images/toothbrushes.jpeg"}
                style={{
                    width: "100%",
                }}
            />
        </div>
    </div>
}

export default Accolades;