import * as React from "react";
import "./index.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import Mission from "./components/pages/mission/Mission";
import Goals from "./components/pages/goals/Goals";
import Accolades from "./components/pages/accolades/Accolades";
import Resume from "./components/pages/resume/Resume";

const App = () => {
    return (
        <BrowserRouter>
            <div id="app-container">
                <Navbar />
                <div id="app-body">
                    <Switch>
                        <Route path="/mission" render={() => <Mission/>}/>
                        <Route path="/goals" render={() => <Goals/>}/>
                        <Route path="/accolades" render={() => <Accolades/>}/>
                        <Route path="/resume" render={() => <Resume/>}/>
                        <Route path="/" render={() => <Home/>}/>
                    </Switch>
                </div>
                <Footer />
            </div>
        </BrowserRouter>
    );
};

export default App;