import { Drawer, IconButton, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from "react-router-dom";
import React, { useState } from "react";

function NavLinkDrawer() {
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
        <>
            <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                id="navbar-drawer"
                anchor={"right"}
            >
                <List className="list">
                    <Link className="link" to="/mission">
                        <ListItem className="listItem" onClick={() => setOpenDrawer(false)}>
                            <ListItemText className="listItemText">
                                Mission
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link className="link" to="/goals">
                        <ListItem className="listItem" onClick={() => setOpenDrawer(false)}>
                            <ListItemText className="listItemText">
                                Goals
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link className="link" to="/accolades">
                        <ListItem className="listItem" onClick={() => setOpenDrawer(false)}>
                            <ListItemText className="listItemText">
                                Accolades
                            </ListItemText>
                        </ListItem>
                    </Link>
                    <Link className="link" to="/resume">
                        <ListItem className="listItem" onClick={() => setOpenDrawer(false)}>
                            <ListItemText className="listItemText">
                                Resume
                            </ListItemText>
                        </ListItem>
                    </Link>
                </List>
                <IconButton className="iconButton" onClick={() => setOpenDrawer(false)}>
                    <CloseIcon className="icon" />
                </IconButton>
            </Drawer>
            <IconButton
                onClick={() => setOpenDrawer(!openDrawer)}
                style={{
                    paddingRight: 20,
                    paddingLeft: 5,
                }}
            >
                <MenuIcon className="icon" />
            </IconButton>
        </>
    );
}
export default NavLinkDrawer;