import { AppBar, CssBaseline, Toolbar } from "@mui/material";
import * as React from "react";
import "./footer.scss";

const Footer = () => {
    return <>
        <CssBaseline />
        <AppBar id="footer-appbar">
            <Toolbar className="toolbar">
                
            </Toolbar>
        </AppBar>
        </>
}

export default Footer;