import React, { createContext, useState, ReactNode } from "react";

export const SchedulingFeatureContext = createContext<[boolean, any]>([false, (_: boolean) => {}]);

const { Provider } = SchedulingFeatureContext;

const SchedulingFeatureProvider = ({ children }: { children?: ReactNode }) => {
    const [featureOn, setFeatureOn] = useState(false);

    return <Provider value={[featureOn, setFeatureOn]}>{children}</Provider>
}

SchedulingFeatureProvider.context = SchedulingFeatureContext;

export default SchedulingFeatureProvider;