import { Typography } from "@mui/material";
import * as React from "react";
import "./resume.scss";


const Resume = () => {
    return <div id="app-resume" className="page-container">
        <section className="fullwide-section">
            <Typography variant="h3" style={{ fontWeight: 400 }}>
                Resume
            </Typography>
        </section>
        <div className="pdf-container">
            <iframe title="resume-iframe" className="pdf-iframe" src={process.env.PUBLIC_URL + "/pdfs/resume.pdf"} />
        </div>
    </div>
}

export default Resume;