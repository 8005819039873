import { createTheme, ThemeProvider } from "@mui/material";
import * as React from "react";
import { render } from "react-dom";
import App from "./App";
import SchedulingFeatureProvider from "./SchedulingFeatureProvider";

const theme = createTheme({
    palette: {
        primary: {
            light: "rgb(90, 158, 186)",
            main: "rgb(90, 158, 186)",
            dark: "rgb(90, 158, 186)",
            contrastText: "#000000",
        },
        secondary: {
            light: "rgb(43, 81, 111)",
            main: "rgb(43, 81, 111)",
            dark: "rgb(43, 81, 111)",
            contrastText: "#000000",
        }
    },
    typography: {
        fontFamily: ['Josefin Sans', 'sans-serif'].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 440,
            md: 700,
            lg: 900,
            xl: 1300,
        }
    },
});

const root = document.getElementById("root");
render(
    <ThemeProvider theme={theme}>
        <SchedulingFeatureProvider>
            <App />
        </SchedulingFeatureProvider>
    </ThemeProvider>, root
);