import { AppBar, Button, CssBaseline, Menu, MenuItem, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import NavLinkDrawer from "./NavLinkDrawer";
import "./navbar.scss";
import { SchedulingFeatureContext } from "../../SchedulingFeatureProvider";  
import TABLE_OF_CONTENTS from "../../constants";

const MenuLinkItem = ({
    name,
    link,
    items,
}: {
    name: string;
    link: string;
    items?: {
        title: string;
        link: string;
    }[]
}) => {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (link?: string) => {
        setAnchorEl(null);
        if (link) {
            history.push(link);
        }
    };
    const goToLink = () => {
        setAnchorEl(null);
        history.push(link);
    }

    return (
        <>
            <Button
                id={"link-" + name}
                className="link"
                aria-controls={open ? ("link-" + name + "-menu") : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={items?.length ? handleOpen : goToLink}
                style={{
                    textTransform: "none",
                }}
            >
                {name}
            </Button>
            <Menu
                id={"link-" + name + "-menu"}
                aria-labelledby={"link-" + name + "-menu"}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {items?.map((item, key) => (<MenuItem onClick={() => handleClose(item.link)} key={key}>{item.title}</MenuItem>)) || <></>}
            </Menu>
        </>
  )
}

const Navbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const showButton = useMediaQuery(theme.breakpoints.up("sm"));
    const [featureOn] = React.useContext(SchedulingFeatureContext);

    return <>
        <CssBaseline />
        <AppBar id="navbar-appbar">
            <Toolbar className="toolbar">
                <Link to="/" style={{textDecoration: "none"}}>
                    <div className="navbar-home-link">
                        <Typography variant="h4" className="typography" style={{
                            fontWeight: 500,
                            color: "black",
                        }}>
                            Nancy Bellflower
                        </Typography>
                        <Typography variant="h6" className="typography" style={{
                            fontSize: 18,
                            fontStyle: "italic",
                            color: "black",
                        }}>
                            Dental Hygiene Student
                        </Typography>
                    </div>
                </Link>
                {featureOn && showButton &&
                    <Link to="/schedule" className="link highlight-bg">
                        Schedule an Appointment
                    </Link>
                }
                {isMobile ? (
                    <NavLinkDrawer />
                ) : (
                    <div className="navlinks" style={{
                        backgroundColor: theme.palette.secondary.main,
                    }}>
                        {TABLE_OF_CONTENTS.map((section, key) => {
                            return (
                                <MenuLinkItem
                                    name={section.buttonName}
                                    link={section.link}
                                    items={section.links}
                                    key={key}
                                />
                            )
                        })}
                    </div>
                )}
            </Toolbar>
        </AppBar>
    </>
}

export default Navbar;